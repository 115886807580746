<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <bread-crumb></bread-crumb>
            <!-- <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
            </div> -->
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" id="leSearch">
                <le-date-range 
                    label="日期" 
                    ref="dateRange"
                    :pickerOptions="pickerOptions" 
                    :minDate.sync="pageParam.params.startDate" 
                    :maxDate.sync="pageParam.params.endDate" 
                    valueFormat="yyyy-MM-dd HH:mm:ss" />
            </le-search-form>
            <le-pagview ref="financeCompany" @setData="setTableData" :pagerCount="5" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.financeCompanyList">
                <el-table ref="financeCompanyList" :data="tableData" :highlight-current-row="true" @filter-change='filterFun' v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="serialNumber" label="交易编号">
                        <template slot-scope="{ row }">
                            <span>{{ row.serialNumber?row.serialNumber:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="timeAllAmount">
                        <template slot="header">
                            <div class="a-flex-cfsfs">
                                <span>日期</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>{{ row.date || '-' }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="timeIncomeAmount">
                        <template slot="header">
                            <div class="a-flex-cfsfs">
                                <span>交易类型</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.timeIncomeAmount) }}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="amount" label="金额">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.amount) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.companyWithdrawDetails,
                    method: "post",
                    params: {
                        companyId: this.$route.query.companyId,
                        endDate: this.$route.query.endDate,
                        startDate: this.$route.query.startDate,
                    },
                    freshCtrl: 1,
                },
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }
                    },
                },
            }
        },
        methods: {
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    companyId: this.$route.query.companyId,
                    endDate: this.$route.query.endDate,
                    startDate: this.$route.query.startDate,
                };
                this.$refs['dateRange'].value1 = [this.$route.query.startDate,this.$route.query.endDate]
                this.handlerSearch()
            },
            handlerSearch() {
                if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startDate,this.pageParam.params.endDate).length > 31){
                    this.$message.error('最大查询范围31天')
                    return
                }
                this.$refs['financeCompany'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            //条件筛选
            filterFun(value){

            },

            exportfile () {
                let startDate = this.pageParam.params.startDate || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
                let endDate = this.pageParam.params.endDate || this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
                this.$exfile({
                    code: 12,
                    fileName: startDate.split(' ')[0] + ' 至 ' + endDate.split(' ')[0] + ' 商户提现表',
                    startTime: startDate.split(' ')[0],
                    endTime: endDate.split(' ')[0],
                    params: {
                        ...this.pageParam.params,
                        startDate: startDate,
                        endDate: endDate
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>